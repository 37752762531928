// in addUploadFeature.js
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
import {loobackApiUrl} from './config';

const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

function sendFile(file, url) {

    return new Promise(function(resolve, reject) {

        var xhr = new XMLHttpRequest();
        var fd = new FormData();

        xhr.open("POST", url, true);
        xhr.onreadystatechange = function() {
            if(xhr.readyState === 4 && xhr.status === 200) {
                resolve(JSON.parse(xhr.responseText));
            }
        };

        var d = new Date();
        var n = d.getTime();
        var type = file.name.split('.').pop();
        var userId = JSON.parse(localStorage.getItem('lbtoken')).value.userId;

        fd.append('file', file, `${userId}-${n}.${type}`);
        xhr.send(fd);

    });
}

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = requestHandler => (type, resource, params) => {

    if ((type === 'UPDATE' || type === 'CREATE') && resource === 'products') {
        // only allow one image upload
        if (params.data.image && params.data.image instanceof Object) {

            return sendFile(params.data.image.rawFile, loobackApiUrl + '/containers/globalplwhs-products/upload')
                .then(function(result) {
                    if(result.result.files && result.result.files.file[0].container) {
                        var location = result.result.files.file[0].providerResponse.location;

                        return requestHandler(type, resource, {
                            ...params,
                            data: {
                                ...params.data,
                                image: location,
                            },
                        });
                    }
                });
        }
    }

    if ((type === 'UPDATE' || type === 'CREATE') && resource === 'stockoutrequests') {

        var promises = [];

        if (params.data.boxLabel && typeof params.data.boxLabel === 'object') {
            promises.push(sendFile(params.data.boxLabel.rawFile, loobackApiUrl + '/containers/globalplwhs-boxlabels/upload'));
        }

        if (params.data.shippingLabel && typeof params.data.shippingLabel === 'object') {
            promises.push(sendFile(params.data.shippingLabel.rawFile, loobackApiUrl + '/containers/globalplwhs-boxlabels/upload'));
        }

        if (params.data.barcodeLabel && typeof params.data.barcodeLabel === 'object') {
            promises.push(sendFile(params.data.barcodeLabel.rawFile, loobackApiUrl + '/containers/globalplwhs-products/upload'));
        }        

        if(params.data.images && typeof params.data.images === 'object') {
            if(typeof params.data.images[0] === 'string') {
                params.data.images.shift();
            }

            params.data.images.forEach(function(newImage) {
                promises.push(sendFile(newImage.rawFile, loobackApiUrl + '/containers/globalplwhs-innerpages/upload'));
            })
        }

        return Promise.all(promises).then(function(results) {
            if(promises.length >= 1) {
                var boxLabel_location = "";
                var shippingLabel_location = "";
                var barcodeLabel_location = "";

                var images = [];

                results.forEach(function(resultOb) {
                    if(resultOb.result.files && resultOb.result.files.file[0].container) {
                        if(resultOb.result.files.file[0].container === "globalplwhs-boxlabels") {

                            if(params.data.type == "FBA"){
                                boxLabel_location = resultOb.result.files.file[0].providerResponse.location;
                            }else if(params.data.type == "FBM"){
                                shippingLabel_location = resultOb.result.files.file[0].providerResponse.location;
                            }


                        } else if(resultOb.result.files.file[0].container === "globalplwhs-products") {
                            barcodeLabel_location = resultOb.result.files.file[0].providerResponse.location;
                        }else if(resultOb.result.files.file[0].container === "globalplwhs-innerpages") {
                            images.push(resultOb.result.files.file[0].providerResponse.location);
                        }
                    }
                })

                var newParams = {};


                if(params.data.type == "FBA"){
                                    
                    if(boxLabel_location === "" && images.length > 0 && barcodeLabel_location === "") {
                        newParams = {images: images}
                    }
                    if(boxLabel_location !== "" && images.length > 0 && barcodeLabel_location === "") {
                        newParams = {boxLabel: boxLabel_location, images: images}
                    }                                
                    if(boxLabel_location !== "" && images.length > 0 && barcodeLabel_location !== "") {
                        newParams = {boxLabel: boxLabel_location, images: images, barcodeLabel: barcodeLabel_location}
                    }
                    if(boxLabel_location !== "" && images.length === 0 && barcodeLabel_location === "") {
                        newParams = {boxLabel: boxLabel_location}
                    }
                    if(boxLabel_location !== "" && images.length === 0 && barcodeLabel_location !== "") {
                        newParams = {boxLabel: boxLabel_location, barcodeLabel: barcodeLabel_location}
                    }
                    if(boxLabel_location === "" && images.length === 0 && barcodeLabel_location !== "") {
                        newParams = {barcodeLabel: barcodeLabel_location}
                    }     
                    if(boxLabel_location === "" && images.length > 0 && barcodeLabel_location !== "") {
                        newParams = {barcodeLabel: barcodeLabel_location, images: images}
                    } 

                }else if(params.data.type == "FBM"){

                    if(shippingLabel_location === "" && images.length > 0) {
                        newParams = {images: images}
                    }
                    if(shippingLabel_location !== "" && images.length > 0) {
                        newParams = {shippingLabel: shippingLabel_location, images: images}
                    }
                    if(shippingLabel_location !== "" && images.length === 0) {
                        newParams = {shippingLabel: shippingLabel_location}
                    }

                }
                
                return requestHandler(type, resource, { 
                        ...params,
                        data: {
                            ...params.data,
                            ...newParams
                        },
                });

            } else {
                return requestHandler(type, resource, params);
            }

        })

    }

    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params);
};

export default addUploadFeature;